import React from 'react';
import Loadable from 'react-loadable';
import SkeletonLoader from './Components/SkeletonLoader';

const WizardLoadable = Loadable({
  loader: () => import('./OrderCreateWizard'),
  loading() {
    return <SkeletonLoader />;
  },
});

export default WizardLoadable;
