import { SessionKeys } from '@app/utils/constants/storage.constant';

// TODO: change to an more specific and non-error prone api.
// example: develop something like "startTracking()" and call it on the first screen of the flow.
async function deleteTrackId() {
  const storage = window.sessionStorage;

  storage.removeItem(SessionKeys.TRACK_ID);
}

export default deleteTrackId;
