import { createGlobalStyle } from 'styled-components';

// language=LESS
const GlobalStyle = createGlobalStyle`
  *:not(input):not(textarea) {
    /* disable text
  selection highlighting */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                     supported by Chrome and Opera */
  }

  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
  }

  /* this is because mercadopago's sdk opens an iframe and it
  takes height in the screen */
  body > iframe {
    display: none;
  }

  html,
  body {
    font-size: 14px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.backgroundBody};
    padding: 0;
    margin: 0 !important;
    font-family: ${(props) => props.theme.fonts.muli}, sans-serif;
    @media (max-width: ${(props) => props.theme.breakpoints.large}) {
      font-size: 12px;
    }

    button:focus,
    input:focus,
    textArea:focus,
    a:focus {
      outline: none;
    }

    & a,
    a:hover {
      text-decoration: none;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      background-color: #ffffff !important;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    .modal__overlay {
      background-color: rgba(39, 50, 57, 0.3);
    }

  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  #courier-app {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }

  .ReactModalPortal {
    z-index: 2;
  }

  .ReactModal__Body--open #courier-app {
    filter: blur(2px);
  }

  .Modal {
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate(-50%, -50%);
    top: 45%;
    position: absolute;
    width: 80%;
    max-width: 570px;
    padding: 40px 20px;
    border-radius: 5px;
    box-shadow: ${(props) => props.theme.containerShadow};
    background: ${(props) => props.theme.colors.White};
    outline: 0;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => props.theme.colors.modalOverlay};
    z-index: 2;
  }

  .hide-info .gm-style-iw-a {
    display: none;
  }

  .gmnoprint, .gm-style-cc {
    bottom: 20px !important;
  }

  #map div div a[target='_blank'] div {
    bottom: 15px;
    position: absolute;
  }

  .ReactSwipeableBottomSheet--closed, .react-swipeable-view-container, .ReactSwipeableBottomSheet--open {
    border-radius: 15px 15px 0 0;
  }


  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(176, 176, 176);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(176, 176, 176);
  }

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: local('Lato Regular'), local('Lato-Regular'),
    url(https://fonts.gstatic.com/s/lato/v14/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
    font-display: swap;
  }

  @font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 300;
    src: local('Muli Light'), local('Muli-Light'),
    url(https://fonts.gstatic.com/s/muli/v12/7Au_p_0qiz-adZnkOCX2z24PMFk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
    font-display: swap;
  }

  @font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 400;
    src: local('Muli Regular'), local('Muli-Regular'),
    url(https://fonts.gstatic.com/s/muli/v12/7Auwp_0qiz-afTLGLQjUwkQ.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
    font-display: swap;
  }


  /* latin */
  @font-face {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 800;
    src: local('Muli ExtraBold'), local('Muli-ExtraBold'),
    url(https://fonts.gstatic.com/s/muli/v12/7Au_p_0qiz-adZXgOCX2z24PMFk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
    font-display: swap;
  }

  button {
    border: none;
    cursor: pointer;
  }
`;

export default GlobalStyle;
