import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getSessionStorage } from '@app/utils/helpers/sessionStorage.helper';
import { SessionKeys } from '@app/utils/constants/storage.constant';
export const PartnerContext = React.createContext({});

function PartnerProvider({ children }) {
  const [partner, setPartner] = useState({});
  const [schedules, setSchedules] = useState([]);
  const storageCountriesString = getSessionStorage(SessionKeys.COUNTRIES_PHONE_CONFIG);
  const storageCountriesObject = JSON.parse(storageCountriesString);
  const [countries, setCountries] = useState(storageCountriesObject?.countries);
  const [hasPoDEnabled, setHasPoDEnabled] = useState(false);

  useEffect(() => {
    setHasPoDEnabled(partner?.partnerConfigurations?.proofOfDelivery?.pin?.enabled || false);
  }, [partner]);

  return (
    <PartnerContext.Provider
      value={{
        partner,
        setPartner,
        schedules,
        setSchedules,
        countries,
        setCountries,
        storageCountriesObject,
        hasPoDEnabled,
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
}

PartnerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PartnerProvider;
