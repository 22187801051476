import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ErrorImg from '@app/assets/img/error.jpg';
import ErrorSubtitle from './styled-components/ErrorSubtitle';
import ErrorTitle from './styled-components/ErrorTitle';
import TextContainer from './styled-components/TextContainer';
import ImageContainer from './styled-components/ImageContainer';
import messages from './messages';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  background-color: 'rgb(252, 252, 254)';
`;

class ErrorHandler extends PureComponent {
  state = { showError: false };
  render() {
    return (
      <div style={{ height: '100%' }} data-testid="handler-test">
        <Container>
          <TextContainer>
            <ErrorTitle>{messages.title.defaultMessage}</ErrorTitle>
            <ErrorSubtitle>{messages.subtitle.defaultMessage}</ErrorSubtitle>
          </TextContainer>
          <ImageContainer>
            <img style={{ height: '100%' }} src={ErrorImg} />
          </ImageContainer>
        </Container>
      </div>
    );
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.node,
};

export default ErrorHandler;
