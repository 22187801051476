import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const InitialDataContext = React.createContext({
  initialData: null,
});

export const useInitialData = () => React.useContext(InitialDataContext);

const InitialDataProvider = ({ children }) => {
  const [initialData, setInitialData] = useState({});
  return (
    <InitialDataContext.Provider
      value={{
        initialData,
        setInitialData,
      }}
    >
      {children}
    </InitialDataContext.Provider>
  );
};

InitialDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default InitialDataProvider;
