import { SessionKeys } from '@app/utils/constants/storage.constant';

async function getTrackId() {
  const storage = window.sessionStorage;

  const possibleTrackId = storage.getItem(SessionKeys.TRACK_ID);

  if (possibleTrackId == null || possibleTrackId.length == 0) {
    const newTrackId = await getNewTrackId();
    storage.setItem(SessionKeys.TRACK_ID, newTrackId);

    return newTrackId;
  }

  return possibleTrackId;
}

async function getNewTrackId() {
  // Here we can generate an crypto-stable UUID, but this is good enough
  // A timestamp will be good enough too
  return Math.trunc(Math.random() * 1000000000).toString();
}

export default getTrackId;
