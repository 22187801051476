import React from 'react';

export const NotificationContext = React.createContext({});

const NotificationProvider = ({ children }) => {
  const [modal, setModal] = React.useState({
    show: false,
    type: '',
    action: 'CLOSE',
    dataModal: null,
  });

  const onCloseModal = () => {
    setModal({ show: false, type: '', action: 'CLOSE', dataModal: null });
  };

  const onConfirmModal = () => {
    setModal({ show: false, type: '', action: 'CONFIRM', dataModal: null });
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <NotificationContext.Provider value={{ modal, setModal, onCloseModal, onConfirmModal }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
