const FONTS = {
  fonts: {
    muli: 'Muli',
    mulish: 'Mulish',
    lato: 'Lato',
  },
  fontWeights: {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    bolder: 900,
  },
  fontSizes: {
    xs: '8px',
    sm: '10px',
    md: '12px',
    lg: '14px',
    xl: '16px',
    xxl: '18px',
  },
};

export default FONTS;
