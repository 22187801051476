import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';

import pkg from '../package.json';
import Root from './root.component';
import ErrorBoundary from './ErrorHandler';

const rootId = `single-spa-application:${pkg.name}`;
let domElement = document.getElementById(rootId);

if (!document.body.contains(domElement)) {
  domElement = document.createElement('section');
  domElement.id = rootId;
  document.body.appendChild(domElement);
}

const lifecycles = singleSpaReact({
  domElementGetter: () => domElement,
  React,
  ReactDOM,
  renderType: () => (domElement.hasChildNodes() ? 'hydrate' : 'render'),
  loadRootComponent: async (props) => {
    try {
      const fwf = await props.fwfPromise;
      const tracker = await props.trackerPromise;
      const context = { locale: `es-${props.initialData?.country.shortName.toUpperCase()}` };
      const messages = await import(`../build/translations/${context.locale}.json`);
      return function (props) {
        return (
          <Root {...props} context={context} tracker={tracker} fwf={fwf} messages={messages} />
        );
      };
    } catch (error) {
      return function () {
        return <ErrorBoundary error={error} />;
      };
    }
  },
  errorBoundary: (error) => <ErrorBoundary error={error} />,
});

export const { bootstrap, mount, unmount, update } = lifecycles;
