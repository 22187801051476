import React from 'react';
import ContentLoader from 'react-content-loader';
import Container from './styled-components/Container';

const Loader = () => (
  <ContentLoader height="600px" width="90%" speed={2} preserveAspectRatio="xMinYMid slice">
    <rect x="calc(55% - 50px)" y="56" rx="3" ry="3" width="100px" height="80" />
    <rect x="calc(55% - 179px)" y="181" rx="3" ry="3" width="358px" height="30" />
    <rect x="calc(55% - 150px)" y="226" rx="3" ry="3" width="300px" height="30" />
    <rect x="calc(55% - 243px)" y="281" rx="3" ry="3" width="486px" height="30" />
    <rect x="calc(55% - 243px)" y="326" rx="3" ry="3" width="486px" height="30" />
  </ContentLoader>
);

const SkeletonLoader = () => (
  <Container>
    <Loader />
  </Container>
);

export default SkeletonLoader;
