const COLORS = {
  White: 'rgb(255, 255, 255)',
  Dark: 'rgb(43, 26, 70, 1)',
  Red: 'rgb(250, 0, 80)',
  Green: 'rgb(1, 201, 140)',
  Main: 'rgb(250, 0, 80)',
  DarkBlue: 'rgb(43 26 70)',
  BrandMain: 'rgb(250, 0, 80)',
  BrandSecondary: 'rgb(45, 134, 244)',
  ContentLoader: 'rgb(239, 244, 249)',
  Elephant: 'rgb(39, 50, 57)',
  Gray: 'rgb(184, 180, 188, 1)',
  LightGray: 'rgb(217, 217, 217)',
  HighLight: 'rgb(252, 253, 196)',
  Transparent: 'transparent',
  NotificationCard: 'rgb(255, 252, 214)',
  OrderCardSelected: 'rgba(0, 0, 0, 0.08)',
  corporateTurquoiseTransparent: 'rgb(0, 189, 237, 0.5)',
  corporateTurquoise: 'rgb(0, 189, 237)',
  orderTypeLabelNew: 'rgb(193, 249, 254)',
  checkBox: 'rgb(43, 26, 70)',
  table: {
    gray: 'rgba(218, 224, 229, 0.4)',
    white: 'rgb(255, 255, 255)',
  },
  buttons: {
    google: 'rgba(45, 134, 244, 1)',
    facebook: 'rgba(59, 89, 152, 1)',
    phone: 'rgb(255, 255, 255)',
    email: 'rgb(255, 255, 255)',
  },

  textPrimary: 'rgb(74, 74, 74)',
  textSecondary: 'rgb(118, 127, 134)',
  textTertiary: 'rgb(250, 0, 80)',
  textHint: 'rgb(169, 177, 183)',
  error: 'rgb(194, 1, 25)',

  elementLine: 'rgb(221, 230, 238)',
  elementLightGrey: 'rgb(246, 248, 248)',
  elementDisable: 'rgb(218, 224, 229)',

  boxShadow: 'rgb(204, 204, 204)',
  backgroundBody: 'rgb(247, 248, 249)',
  backgroundGrey: 'rgb(252, 252, 254)',
  rowErrorBackground: 'rgb(250, 0, 80, .1)',

  dividerGrey: 'rgb(240, 240, 240)',
  modalOverlay: 'rgba(0,0,0, 0.3)',
  navBackGround: 'rgb(250, 0, 80)',

  grayTransparent: 'rgb(205, 205, 205, 0.3)',
  grayText: 'rgb(118, 127, 134)',

  elementLineTransparent: 'rgb(221, 230, 238, 0.4)',

  pending: 'rgb(118, 127, 134)',
  picked_up: 'rgb(74, 74, 74)',
  cancelled: 'rgb(169, 177, 183)',
  completed: 'rgb(1, 201, 140, .1)',
  bulkFileUploadBack: 'rgb(247, 247, 247)',

  core: {
    darkOnDefault: '#100423',
  },

  utilities: {
    secondaryLighter: '#DBF9FE',
    secondaryDarker: '#002CC1',
  },

  complementary: {
    loud: '#2B1A46',
    quiet: '#EFEDF0',
  },
};

export default COLORS;
