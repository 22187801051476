import COLORS from './colors';
import BREAKPOINTS from './breakpoints';
import SPACES from './spaces';
import FONTS from './fonts';

const orderingFlowTheme = {
  ...FONTS,
  colors: {
    ...COLORS,
  },
  breakpoints: {
    ...BREAKPOINTS,
  },
  spaces: {
    ...SPACES,
  },
  containerShadow: '0 2px 6px 0 rgba(39, 50, 57, 0.15)',
  orderStatuses: {
    pending: 'rgb(118, 127, 134)',
    in_progress: 'rgb(74, 74, 74)',
    near_pickup: 'rgb(74, 74, 74)',
    picked_up: 'rgb(74, 74, 74)',
    near_dropoff: 'rgb(74, 74, 74)',
    cancelled: 'rgb(169, 177, 183)',
    completed: 'rgb(1, 201, 140)',
  },
};

export default orderingFlowTheme;
