import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: -webkit-fill-available;
  margin: 5%;
  height: auto;
  background-color: ${(props) => props.theme.colors.White};
`;

export default Container;
