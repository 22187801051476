import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import OrderCreateWizard from '@app/Containers/Pages/OrderCreateWizard';
import ROUTES from '@app/Routes';
import BulkOrdersWizard from './Containers/Pages/BulkOrdersWizard';

const RouterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.landing ? 'auto' : '100vh')};
`;

const Main = ({
  onCloseEnterpriseTipModal,
  showNewEnterpriseModal,
  showBeRightBackModal,
  onChangeStep,
  onResolveModalExit,
  onRejectModalExit,
  onChangeStopNavigation,
  routeSelected,
  enableModalOrdersMultiple,
  setEnablePoD,
}) => (
  <RouterContainer>
    <Switch>
      <Route
        path={ROUTES.ORDER_CREATE}
        exact
        render={() => (
          <OrderCreateWizard
            showNewEnterpriseModal={showNewEnterpriseModal}
            onCloseEnterpriseTipModal={onCloseEnterpriseTipModal}
            showBeRightBackModal={showBeRightBackModal}
            onChangeStep={onChangeStep}
            enableModalOrdersMultiple={enableModalOrdersMultiple}
            setEnablePoD={setEnablePoD}
          />
        )}
      />
      <Route
        path={ROUTES.ORDERS_MULTIPLE}
        exact
        render={() => (
          <BulkOrdersWizard
            onResolveModalExit={onResolveModalExit}
            onRejectModalExit={onRejectModalExit}
            onChangeStopNavigation={onChangeStopNavigation}
            routeSelected={routeSelected}
          />
        )}
      />
    </Switch>
  </RouterContainer>
);

export default withRouter(Main);
