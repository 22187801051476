import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState, createContext, useMemo } from 'react';
import axios from 'axios';
import BrandLoader from '@appscore/web-components/BrandLoader';
import { BrandError } from '@appscore/web-components/BrandError';

import { InitialDataContext } from '@app/Providers/InitialData';

const PublicEnvContext = createContext({});

export const getPublicEnv = async () => {
  const { data } = await axios.get(`/api/courier-business-main/env`);
  return data;
};

export const usePublicEnv = () => useContext(PublicEnvContext);

// eslint-disable-next-line no-shadow
export function PublicEnvProvider({ children, context, getPublicEnv, initialData }) {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);
  const { setInitialData } = React.useContext(InitialDataContext);

  useEffect(() => {
    getPublicEnv()
      .then((envData) => {
        setPublicEnv({
          ...publicEnv,
          envData,
        });
        setInitialData({
          ...initialData,
        });
      })
      .catch(setError);
  }, []);

  const refreshPublicEnv = async () => {
    const result = await getPublicEnv();

    if (result?.initialData) {
      setPublicEnv({ ...publicEnv, envData: result });
      setInitialData(result);
    }
  };

  const value = useMemo(() => ({ ...publicEnv, refreshPublicEnv }), [publicEnv, refreshPublicEnv]);

  if (error) return <BrandError />;

  if (!publicEnv) return <BrandLoader />;

  return <PublicEnvContext.Provider value={value}>{children}</PublicEnvContext.Provider>;
}

export const publicEnvShape = {
  locale: PropTypes.string,
  device: PropTypes.string,
  environment: PropTypes.string,
  country: PropTypes.shape({
    id: PropTypes.number,
  }),
};
