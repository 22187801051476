import loadScript from './scripts';

export const SCRIPT_ID = 'google_maps';
const defaultCallback = () => {};

function loadGoogleMaps(googleMapsKey) {
  const dhIdsQuery = `key=${googleMapsKey}`;
  const src = `https://maps.googleapis.com/maps/api/js?${dhIdsQuery}&language=es&callback=onMapLoad`;
  loadScript({ src, defaultCallback, id: SCRIPT_ID });
}

export default loadGoogleMaps;
