export const SessionKeys = {
  TRACK_ID: 'trackId',
  DEVICE_ID: 'deviceId',
  BULK_ID: 'bulkId',
  COUNTRIES_PHONE_CONFIG: 'main.countries_phone_config',
};

export const LocalKeys = {
  FIRST_TIME_STACKING_MODAL: 'ordering_flow.first_time_stacking_modal',
  FIRST_TIME_CASH_COLLECTION_MODAL: 'ordering_flow.first_time_cash_collection_modal',
  FIRST_TIME_BULK_ORDERS_MODAL: 'ordering_flow.first_time_bulk_orders_modal',
};
