import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import InitialDataProvider from '@app/Providers/InitialData';
import { QueryClient, QueryClientProvider } from 'react-query';
import loadGoogleMaps from '@commons/utils/scripts/loadGoogleMaps';
import { setInstance } from '@app/tracking';
import { createIntlProvider, getMessages } from './contexts/IntlProvider';
import PartnerProvider from './Providers/PartnerProvider';
import ShipmentProvider from './Providers/OrderingServiceProvider';
import GlobalStyleSheet from './styles';
import Main from './Main';
import theme from './theme';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from './contexts/PublicEnv';
import NotificationProvider from './Providers/NotificationProvider';

const IntlProvider = (props) => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
let history = {};
if (typeof window !== 'undefined') {
  history = createBrowserHistory();
}

function Root({ context, messages, initialData, track, ...props }) {
  React.useEffect(() => {
    window.onMapLoad = () => {
      delete window.onMapLoad;
    };
  }, []);

  React.useEffect(() => {
    if (initialData) {
      setInstance(track, initialData);
      loadGoogleMaps(initialData.googleMapsKey);
    }
  }, [initialData]);

  return (
    <InitialDataProvider>
      <PublicEnvProvider context={context} getPublicEnv={getPublicEnv} initialData={initialData}>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyleSheet />
            <IntlProvider initialMessages={messages}>
              <QueryClientProvider client={queryClient}>
                <ShipmentProvider>
                  <PartnerProvider>
                    <NotificationProvider>
                      <Router history={history}>
                        <Main {...props} />
                      </Router>
                    </NotificationProvider>
                  </PartnerProvider>
                </ShipmentProvider>
              </QueryClientProvider>
            </IntlProvider>
          </>
        </ThemeProvider>
      </PublicEnvProvider>
    </InitialDataProvider>
  );
}

// eslint-disable-next-line no-underscore-dangle
const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
};

export default Root;
