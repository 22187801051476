import React, { useState } from 'react';
import PropTypes from 'prop-types';
export const SessionContext = React.createContext({});

function OrderingServiceProvider({ children }) {
  const [session, setSession] = useState({});
  const [sessionBulk, setSessionBulk] = useState({
    scheduleDetails: {
      date: '',
      type: '',
    },
  });
  return (
    <SessionContext.Provider
      value={{
        session,
        setSession,
        sessionBulk,
        setSessionBulk,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

OrderingServiceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrderingServiceProvider;
