export const saveSessionStorage = (key, value) => {
  if (typeof key !== 'string') {
    throw Error('Error saving in sessionStorage: Key must be string value');
  }

  if (typeof value !== 'string') {
    throw Error('Error saving in sessionStorage: Value must be string value');
  }

  sessionStorage.setItem(key, value);
};

export const getSessionStorage = (key) => {
  if (typeof key !== 'string') {
    throw Error('Error getting from sessionStorage: Key must be string value');
  }
  return sessionStorage.getItem(key);
};

export const removeSessionStorage = (key) => {
  if (typeof key !== 'string') {
    throw Error('Error deleting from sessionStorage: Key must be string value');
  }

  sessionStorage.removeItem(key);
};

export default { getSessionStorage, saveSessionStorage, removeSessionStorage };
