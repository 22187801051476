import React from 'react';
import ContentLoader from 'react-content-loader';
import Container from './styled-components/Container';
import Right from './styled-components/Right';
import Left from './styled-components/Left';

const LoaderLeft = () => (
  <ContentLoader height="600px" width="90%" speed={2} preserveAspectRatio="xMinYMid slice">
    <rect x="30" y="50" rx="3" ry="3" width="calc(100% - 20px)" height="30" />
    <rect x="30" y="125" rx="3" ry="3" width="calc(100% - 20px)" height="30" />
    <rect x="30" y="200" rx="3" ry="3" width="48%" height="30" />
    <rect x="calc(50% + 30px)" y="200" rx="3" ry="3" width="48%" height="30" />
    <rect x="30" y="275" rx="3" ry="3" width="calc(100% - 20px)" height="30" />
    <rect x="30" y="calc(100% - 150px)" rx="3" ry="3" width="calc(100% - 20px)" height="130" />
    {/* <rect x="30" y="181" rx="0" ry="0" width="540" height="150" /> */}
  </ContentLoader>
);

const SkeletonLoader = () => (
  <Container>
    <Left>
      <LoaderLeft />
    </Left>
    <Right />
  </Container>
);

export default SkeletonLoader;
