import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: -webkit-fill-available;
  margin: 5%;
  height: 100%;
  flex-grow: 1;
`;

export default Container;
